import React from 'react'
import SEO from '../components/Seo'

const NotFoundPage = () => (
  <>
    <SEO
      title="Innential - Not Found"
      description="Innential helps your teams perform and grow through hyper personalised learning."
    />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </>
)

export default NotFoundPage
